// my-web-app/src/components/ProductModal.tsx
import React, { useEffect, useRef, useCallback } from 'react';
import { Product, displayPrice, formatProductNameForUrl } from '../utils/types'; // Import the types
import ProductDetails from './ProductDetails'; // Import the new component
import { IconContext } from "react-icons";
// import { FaArrowLeftLong, FaX, FaShieldHeart, FaLink } from "react-icons/fa6";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FaArrowLeftLong, FaX, FaShieldHeart, FaLink, FaFacebook, FaTelegram, FaLine, FaTwitter, FaWhatsapp } from "react-icons/fa6";
import { IoLogoWechat } from "react-icons/io5";

interface ProductModalProps {
    product: Product;
    onClose: () => void;
}

const ProductModal: React.FC<ProductModalProps> = ({ product, onClose }) => {
    // const [quantity, setQuantity] = useState(1);
    const modalRef = useRef<HTMLDivElement>(null); // Create a ref for the modal

    const handleOutsideClick = useCallback((event: MouseEvent) => {
        if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
            onClose(); // Close the modal if clicked outside
        }
    }, [onClose]);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onClose(); // Close the modal on Escape key press
        }
    }, [onClose]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        document.addEventListener('keydown', handleKeyDown);

        // Disable scroll on modal open
        document.body.style.overflow = 'hidden';

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
            document.removeEventListener('keydown', handleKeyDown);


            // Re-enable scroll on modal close
            document.body.style.overflow = '';
        };


    }, [handleOutsideClick, handleKeyDown]); // Include the functions in the dependency array


    // Social share URLs with the current page link
    // const currentUrl = encodeURIComponent(window.location.href);
    const shareText = encodeURIComponent(`Check out this product: ${product?.product_name}`);

    // Build the Open Graph and SEO tags dynamically based on the product
    const productUrl = window.location.href;

    // const productImage = product?.media && product.media.length > 0 ? product.media[0].original_url : '../images/df.jpg';
    // const productName = product?.product_name || 'Product';
    // const productDescription = product?.product_desc || 'No description available';
    const productUrlName = encodeURIComponent(productUrl + 'product/' + product.id + '/' + formatProductNameForUrl(product.product_name));

    return (
        <div className="z-30 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 product-modal">

            {/* Modal body */}
            <div ref={modalRef} className="w-screen h-screen overflow-y-auto bg-white p-1">

                {/* Modal header */}
                <div className='z-50 fixed top-0 w-full flex flex-rowjustify-between bg-white'>

                    <button
                        onClick={onClose}
                        className="text-3xl text-blue-600 bg-white rounded-full p-1"
                        aria-label="Close"
                    >
                        <FaArrowLeftLong />
                    </button>

                    {/* {productUrl + '' + productUrlName} */}

                    <button
                        onClick={onClose}
                        className="absolute top-2 right-2 text-xl text-white bg-red-600 rounded-full p-1"
                        aria-label="Close"
                    >
                        <FaX />
                    </button>

                </div>

                <div className='mt-0 flex flex-col md:flex-row'>

                    <div className='w-full md:w-2/5 p-4'>

                        <h2 className="product-name w-full fixed top-8 left-0 bg-white  text-xl font-bold mt-0 mb-5 px-4 py-4 pb-8">{product.product_name}</h2>

                        <div className=" md:sticky md:top-20 mt-28 h-96 shadow-lg rounded-xl">

                            <img
                                src={product.media.length > 0 ? product.media[0].original_url : 'df.jpg'}
                                alt={product.product_name}
                                style={{
                                    // width: '100vw',
                                    // height: '100vh',
                                    // objectFit: 'contain',
                                }}
                            />
                        </div>

                        {/* <div className="fixed bottom-10 flex item-center mt-4">
                            <button className='text-lg bg-gray-400 px-4 py-1 shadow-lg rounded-lg' onClick={() => setQuantity(prev => Math.max(1, prev - 1))}>-</button>
                            <span className='text-lg bg-gray-50 px-4 pt-3 shadow-lg rounded-lg'>{quantity}</span>
                            <button className='text-lg bg-gray-400 px-4 py-1 shadow-lg rounded-lg' onClick={() => setQuantity(prev => prev + 1)}>+</button>
                            <button onClick={onClose} className="m-1 bg-blue-500 text-white p-2 shadow-lg rounded-lg">Add to Cart</button>
                        </div> */}

                    </div>

                    <div className='w-full h-full md:w-3/5 p-4 pb-5 m-0 mt-0 md:mt-20 mb-36 md:mb-36 bg-gray-100 bg-opacity-70 rounded-xl shadow-xl product-details-box'>

                        {/* Social Share Icons */}
                        <div className="social-share flex justify-center mt-4 space-x-4">
                            {/* <div className="flex justify-center mt-4 space-x-4"> */}
                            <a href={`https://www.facebook.com/sharer/sharer.php?u=${productUrlName}`} target="_blank" rel="noopener noreferrer" className='mr-2'>
                                <IconContext.Provider value={{ size: "1.6em", color: "#1877F2" }}>
                                    <FaFacebook />
                                </IconContext.Provider>
                            </a>

                            <a href={`https://social-plugins.line.me/lineit/share?url=${productUrlName}`} target="_blank" rel="noopener noreferrer" className='mr-2'>
                                <IconContext.Provider value={{ size: "1.6em", color: "#00C300" }}>
                                    <FaLine />
                                </IconContext.Provider>
                            </a>

                            <a href={`https://t.me/share/url?url=${productUrlName}`} target="_blank" rel="noopener noreferrer" className='mr-2'>
                                <IconContext.Provider value={{ size: "1.6em", color: "#0088cc" }}>
                                    <FaTelegram />
                                </IconContext.Provider>
                            </a>

                            <a href={`weixin://dl/officialaccounts?scene=108&need_open_webview=0&url=${productUrlName}`} target="_blank" rel="noopener noreferrer" className='mr-2'>
                                <IconContext.Provider value={{ size: "1.6em", color: "#1AAD19" }}>
                                    <IoLogoWechat />
                                </IconContext.Provider>
                            </a>
                            <a
                                href={`https://twitter.com/intent/tweet?url=${productUrlName}}&text=${shareText}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Share on Twitter"
                                className="text-blue-400"
                            >
                                <FaTwitter size={28} />
                            </a>
                            <a
                                href={`https://api.whatsapp.com/send?text=${shareText}%20${productUrlName}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Share on WhatsApp"
                                className="text-green-500"
                            >
                                <FaWhatsapp size={28} />
                            </a>
                        </div>

                        <p className="mt-2 text-sm text-blue-600 font-semibold break-all">
                            Category: {product.cat_name}
                        </p>
                        <p className="mt-2 text-sm text-blue-600 font-normal break-all">
                            SKU: {product.product_code}
                        </p>

                        <p className='mt-2 mb-4 text-lg font-seminbold'>{product.product_desc}</p>

                        <ProductDetails product={product} /> {/* Use your new component here */}

                    </div>


                </div>

                <div className="z-40 absolute bottom-0 right-0 m-0 pt-4 pb-20 md:pb-10 w-screen flex justify-center bg-gray-200 rounded-t-3xl ">

                    {/* {isAuthenticated() && hasRole(['dealer']) && product?.id !== undefined && (

                                    <button className="m-1 bg-green-100 text-black p-2 shadow-lg rounded-lg">
                                        <GetMTPrice productId={product.id} />
                                    </button>
                                )} */}
                    <div className='flex flex-row justify-start mr-2'>

                        {/* Conditional rendering for product link icon */}
                        {product.product_link && (
                            <a
                                href={product.product_link}
                                className='mr-2'
                                target="_blank"
                                rel='noreferrer'
                                data-tooltip-id={`link${product.id}`}
                                data-tooltip-content={`Go to Product Details Link`}
                            >
                                <IconContext.Provider value={{ size: "1.6em", color: "purple", className: "" }}>
                                    <div>
                                        <FaLink />
                                    </div>
                                </IconContext.Provider>

                                {/* <ReactTooltip
                                    id={`link${product.id}`}
                                    place="left"
                                    style={{ backgroundColor: "yellow", color: "#000000" }}
                                /> */}
                            </a>
                        )}

                        {/* Conditional rendering for warranty icon */}
                        {product.warranty && (
                            <IconContext.Provider value={{ size: "1.6em", color: "brown", className: "" }}>
                                <div
                                    data-tooltip-id={`warranty${product.id}`}
                                    data-tooltip-content={`${product.warranty} warranty`}
                                >
                                    <FaShieldHeart />
                                </div>
                            </IconContext.Provider>
                        )}

                        {/* Tooltip for warranty icon */}
                        {product.warranty && (
                            <ReactTooltip
                                id={`warranty${product.id}`}
                                place="top"
                                style={{ backgroundColor: "yellow", color: "#000000" }}
                            />
                        )}
                    </div>

                    {product?.MTPriceArray && product.MTPriceArray.length > 0 && (
                        <div>
                            {/* <h3>MT Prices:</h3> */}
                            <ul className='flex flex-row'>
                                {product.MTPriceArray.map((item, index) => (
                                    <li key={index} className="mx-1 my-0 px-2 pb-1 bg-green-600 text-lg md:text-xl text-white shadow-lg rounded-lg">
                                        <span className='my-0 py-0 text-xs text-wite font-semibold' style={{ fontSize: '1em' }}>{item.cat_name}: {displayPrice(item.price)}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {product?.MTPrice !== undefined && product?.MTPrice > 0 && (
                        <button className="mx-2 my-0 px-4 py-1 bg-green-600 text-lg md:text-xl text-white  shadow-lg rounded-lg">
                            <span className='text-sm'>VIP </span><span className='font-bold'>RM{displayPrice(product.MTPrice)}</span>
                        </button>
                    )}

                    <button className="mx-2 my-0 px-4 py-1  bg-black text-lg font-bold md:text-xl text-white  shadow-lg rounded-lg">RM {displayPrice(product?.product_value) || 'Product Price Not Available'}</button>

                    {/* <button className='text-lg bg-gray-400 px-4 py-1 shadow-lg rounded-lg' onClick={() => setQuantity(prev => Math.max(1, prev - 1))}>-</button>
                            <span className='text-lg bg-gray-50 px-4 pt-3 shadow-lg rounded-lg'>{quantity}</span>
                            <button className='text-lg bg-gray-400 px-4 py-1 shadow-lg rounded-lg' onClick={() => setQuantity(prev => prev + 1)}>+</button>
                            <button className="m-1 bg-blue-500 text-white p-2 shadow-lg rounded-lg">Add to Cart</button> */}

                </div>
            </div>

        </div>
    );
};

export default ProductModal;